import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Stránka nenalezena" />
      <h1>Stránka nenalezena</h1>
    </Layout>
  );
};

export default NotFoundPage;
